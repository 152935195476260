$( document ).ready( function(){
    //detect IE
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident/.test(ua);

    if ( isIE ) {
        $("#ie-warning").show();
    }


	if(typeof initPhotoSwipeFromDOM == "function"){
		initPhotoSwipeFromDOM('.my-gallery');
	}

	var mobileMenuPlugin = new MobileMenuPlugin();

	mobileMenuPlugin.init();
    /*
    var slickCarouselPlugin = new SlickCarouselPlugin();
    */

	/*   zobrazovanie dlazdic po kliku na linky v prvej karte na homepage  */

	var fadeInDuration = 200;

	$('body').on('click', 'a[href="#show-info-boxes"]', function(e) {
        e.preventDefault();
        $(this).parents('.card-text').hide();
        $(this).parents('.card-homepage').find(".info-boxes-original-header").hide();
        $(this).parents('.card-homepage').find(".info-boxes-temp-header").text($(this).text()).fadeIn(fadeInDuration);
        $('#' + $(this).data('target')).fadeIn(fadeInDuration);
    });

    $('body').on('click', '.back-link', function(e) {
    	e.preventDefault();
    	$(this).parents('.card-homepage').find(".info-boxes-temp-header").hide();
    	$(this).parents('.card-homepage').find(".info-boxes-original-header").fadeIn(fadeInDuration);
    	$(this).parent().hide();
    	$(this).parent().siblings('.card-text').fadeIn(fadeInDuration);
    }); 

    /* ***** */
    $(".tabs-buttons button").click(function(e){
    	var el = $(e.target);
    	var target = $("#"+el.attr("data-tab"));

    	if(!$(".tabs").hasClass('shown')){
    		$(".tabs").show();
    		$(".tabs-buttons").addClass('shown');
    	}

    	el.addClass("clicked").siblings().removeClass("clicked");
    	target.siblings().hide();
    	target.fadeIn(fadeInDuration);
    });

    $( window ).on('load', function() {
        /*  listen on hash when page loads*/
        var newHash = window.location.hash;
        if($(newHash).length > 0){
            var head = $(newHash);
            head.click();
            head.attr("id", newHash);
            $('html, body').animate({
                    scrollTop: head.offset().top - 20
                }, 'slow');
        }
    });

    var mySwiper = new Swiper('.job-offers-swiper', {
        loop: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
    });

    $(".card-header").click(function(e){
        var myhash = "h-" + $(this).attr("data-target").substring(1);
        $("#"+myhash).removeAttr("id");
        //window.location.hash = myhash;
        window.location.replace("#"+myhash); 
        $("#"+myhash).attr("id", myhash);
        
    });

    var vid_links = $("a[data-video]");
    if(vid_links.length > 0){
        createVideoOverlay();
        initVideoViewer();
        bindCloseControls();

        vid_links.click(function(e){
            e.preventDefault();
            var el = $(e.currentTarget);
            var vid_id = el.attr("data-video");
            showVideo(vid_id);
            return false;
        });
    }

    function resizeCardImageLeft(){
        $.each($(".card-image-left .left-image"), function(i,el){
            $leftImage = $(el);
            $leftImage.height($leftImage.children(".flip-card-front").children("img").height());

            $rightText = $leftImage.siblings(".right-text");
            
            if($rightText.height() != $leftImage.height()){
                var paddY = $rightText.innerHeight() - $rightText.height();
                
                if($(window).width() > 767) {
                    $rightText.height($leftImage.height() - paddY);
                }
                else {
                    $rightText.css('height','auto');;
                }
            }
        });
    };

    setTimeout(resizeCardImageLeft, 1000);
    setTimeout(resizeCardImageLeft, 10000);
    
    if($(".card-image-left .left-image").length > 0) {
        $(window).resize(function() {
            resizeCardImageLeft();
        });

        /* animacia flipovania obrazkov */
        $(window).on('scroll', function() {
            var distance = Number.MAX_VALUE;
            var obj;
            $('.left-image').each(function() {
                if($(this).isFullyInViewport()){
                    if($(this).distanceToScreenCenter() < distance){
                        distance = $(this).distanceToScreenCenter();
                        obj = $(this);
                    }
                }
            });

            if(typeof obj != "undefined" && !obj.hasClass("inCenter")){
                obj.addClass("inCenter");
            }
            
        });
    }
} );

$.fn.distanceToScreenCenter = function() {
    var elementTop = $(this).offset().top;
    var elementCenter = elementTop + ($(this).outerHeight() / 2);

    var viewportTop = $(window).scrollTop();
    var viewportCenter = viewportTop + ($(window).height() / 2);

    return Math.abs(elementCenter - viewportCenter);
};

$.fn.isFullyInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementTop >= viewportTop && elementBottom <= viewportBottom;
};

/**************** YTB video player ********************/
var player;
function initVideoViewer() {
    var tag = document.createElement('script');

    tag.src = "//www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    
};
function onYouTubeIframeAPIReady() {
    player = new YT.Player('player', {
        height: '405',
        width: '720'
    });
}

function createVideoOverlay(){
    $('<div class="video-overlay" style="display:none;">'+
		'<div class="player-wrapper">'+
			'<div class="close-button">Zatvoriť</div>'+
			'<div id="player"></div>'+
		'</div>'+
	'</div>').appendTo($("main"));
}

function bindCloseControls(){
    $(document).keyup(function(e) {
        if (e.key === "Escape") {
            hideVideo();
        }
    });

    $(".video-overlay").click(function(e){
        hideVideo();
    });
}

function hideVideo() {
    $(".video-overlay").fadeOut(250);
    player.stopVideo();
}

function showVideo(id) {
    player.loadVideoById(id);
    $(".video-overlay").fadeIn(250);
}

/********************************** */
/*
function SlickCarouselPlugin() {
    var self = this;
    this.carousel = [];
    $(".carousel").each(function(index) {
        self.carousel.push($(this));
        if(self.carousel[index] != undefined && self.carousel[index].length > 0) {
            $(self.carousel[index]).attr("data-carousel-id", index);
             self.carousel[index].slick({
                dots: false,
                arrows: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                adaptiveHeight: true
            }); 
            $(".carousel-control-next").click(function(e) {
                e.preventDefault();
                var id = $(this).parents(".carousel").data("carousel-id");
                self.carousel[id].slick('slickNext');            
            });
            $(".carousel-control-prev").click(function(e) {
                e.preventDefault();
                var id = $(this).parents(".carousel").data("carousel-id");
                self.carousel[id].slick('slickPrev');    
            });
            $(".carousel-dots li").click(function(e) {
                e.preventDefault();
                var id = $(this).parents(".carousel").data("carousel-id"),
                    slideId = $(this).data("carousel-go-to");
                self.carousel[id].slick('slickGoTo', slideId);    
            });
        };
    });
}
*/ 
function MobileMenuPlugin(){

	var self = this;

	/* Init mobile menu */
	this.init = function(){

		$( "#mobile-menu a" ).each( function( index, elem ){

			$( elem ).on( "click.menu", self.onClick );
		} );

		$( "#mobile-menu .menu-card .close" ).each( function( index, elem ){

			$( elem ).on( "click.close", self.onClose );
		} );

		$( "#mobile-menu .menu-card .has-children" ).each( function( index, elem ){

			$( elem ).on( "click.toggleSection", self.toggleSection );
		} );
	};

	/* Activate menu card */
	this.onClick = function( event ){

		var menuCardId = $( this ).attr( "data-menu-card" );

		//self.onClose();

		$( "#" + menuCardId ).toggleClass( "active" );
        $( this ).toggleClass( "active" );
        
        $( this ).parent().siblings().children().removeClass( "active" );
        $( "#" + menuCardId ).siblings(".menu-card.active").removeClass( "active" );

        if($(".menu-card.active").length == 0){
            $( "body" ).removeClass( "mobile-menu-active" );
        }
        else {
            $( "body" ).addClass( "mobile-menu-active" );
        }
        

		//return false;
	};

	/* Close all cards on click x */
	this.onClose = function(){

		$( "body" ).removeClass( "mobile-menu-active" );
		$( "#mobile-menu .menu-card" ).removeClass( "active" );
		$( "#mobile-menu a" ).removeClass( "active" );
	};

	/* Toggle sub section on click +- */
	this.toggleSection = function(){

		var opened = $( this ).hasClass( "active" );

		$( this ).parent().find( "li" ).removeClass( "active" );

		if( !opened ) {
            $( this ).addClass( "active" ).next().addClass( "active" );
		}

	};
}